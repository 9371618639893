<template>
  <div id="printMe">
    <v-container>
      <v-row align="center" justify="center" class="text-center" no-gutters>
        <v-card width="90%" class="pa-10">
          <v-row align="center" justify="center" class="text-center" no-gutters>
            <v-img
              src="@/assets/logo_survey.png"
              max-width="180px"
              max-height="180px"
            />
          </v-row>
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <p style="font-size: 24px" class="headtitle">
              ระบบบันทึกข้อมูลการ X-Ray
            </p>
            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  label="ชื่อผู้บันทึกข้อมูล"
                  v-model="createdUser"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="6">
                <v-text-field
                  label="วัน/เวลา ที่อัพเดทข้อมูล"
                  v-model="updatedAt"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="6">
                <v-text-field
                  label="รหัสประจำตัวประชาชน/ เลขพาสปอร์ต"
                  v-model="identityId"
                  counter="13"
                  :rules="rules.maxlength"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="name"
                  label="ชื่อ-นามสกุล"
                  :rules="rules.required"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="nickname"
                  label="ชื่อเล่น"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="gender"
                  label="เพศ"
                  :items="['ชาย', 'หญิง']"
                  :rules="rules.required"
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="age"
                  label="อายุ"
                  :rules="rules.maxAge"
                  type="number"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="nationality"
                  label="สัญชาติ"
                  :items="['ไทย', 'ลาว', 'เมียร์มาร์', 'กัมพูชา', 'อื่นๆ']"
                  :rules="rules.required"
                  disabled
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
                v-if="nationality == 'อื่นๆ'"
                :rules="rules.required"
              >
                <v-text-field
                  v-model="otherNationality"
                  label="สัญชาติอื่นๆ ระบุ"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="address"
                  label="ที่อยู่"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine"
                  :items="urineItems"
                  label="การตรวจปัสสาวะบุคคล"
                  :rules="rules.required"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-checkbox
                  v-model="acceptFlag"
                  label="สมัครใจบำบัด"
                  disabled
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="urine == 'พบสารเสพติด' || acceptFlag == true">
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="phone"
                  label="หมายเลขโทรศัพท์"
                  counter="10"
                  type="number"
                  :rules="rules.maxlength10"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="caseType"
                  :items="[
                    'เสพ',
                    'จำหน่าย',
                    'ครอบครองเพื่อจำหน่าย',
                    'ครอบครอง',
                  ]"
                  label="ลักษณะการทำความผิด"
                  :rules="rules.required"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="drugTimes"
                  :items="[
                    'ผู้ใช้ (ผู้เสพรายใหม่เพิ่งเสพครั้งแรก)',
                    'ผู้เสพ (เคยเสพมาก่อน ผ่านการบำบัดไม่เกิน 5 ครั้ง)',
                    'ผู้ติด (เคยเสพมาก่อน ผ่านการบำบัดมากกว่า 5 ครั้ง',
                    'ผู้ติด+ผู้ป่วยจิตเวชจากการใช้สารเสพติด',
                  ]"
                  label="ลักษณะการเสพ"
                  :rules="rules.required"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12" v-if="pic1">
                <h3>รูปภาพที่ 1</h3>
                <v-img :src="pic1" max-width="20vh"
              /></v-col>
              <v-col cols="12" md="12" sm="12" xs="12" v-if="pic2">
                <h3>รูปภาพที่ 2</h3>
                <v-img :src="pic2" max-width="20vh"
              /></v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="picture_1"
                  @click="changePic1()"
                  :placeholder="imageName1"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/*"
                  @change="showPicture1"
                  style="display: none"
              /></v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  v-model="picture_2"
                  @click="changePic2()"
                  :placeholder="imageName2"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure2"
                  accept="image/*"
                  @change="showPicture2"
                  style="display: none"
              /></v-col> -->
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="drugType"
                  :items="[
                    'ยาบ้า',
                    'เฮโรอีน',
                    'ไอซ์',
                    'กัญชา',
                    'กระท่อม',
                    'ยาเค',
                    'อื่นๆ',
                  ]"
                  label="สารเสพติดที่พบ"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="drugType == 'อื่นๆ'">
                <v-text-field
                  v-model="drugTypeOther"
                  label="ระบุสารเสพติดที่พบ"
                  :rules="rules.required"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="otherPerson"
                  :items="['มี', 'ไม่มี']"
                  label="บุคคลผู้เกี่ยวข้อง"
                  :rules="rules.required"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="otherPerson == 'มี'">
                <v-text-field
                  v-model="otherPersonDetail"
                  label="รายละเอียดผู้เกี่ยวข้อง"
                  :rules="rules.required"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <h3 style="font-weight: 800; color: #833133">หลังการบำบัด</h3>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="treatment"
                  :items="['เข้ารับการบำบัด', 'ไม่เข้ารับการบำบัด']"
                  label="เข้ารับการบำบัดหรือไม่"
                  :rules="rules.required"
                  disabled
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" md="6" sm="12" xs="12">
                <v-select
                  v-model="afterDrug"
                  :items="afterDrugitems"
                  label="การดำเนินชีวิตหลังการบำบัด"
                  disabled
                ></v-select>
              </v-col> -->
              <v-col cols="12" md="6" sm="12" xs="12">
                <v-text-field
                  v-model="updatedAt"
                  label="อัพเดตล่าสุดเมื่อ"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine2"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 2"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine2">
                <v-menu
                  ref="menufrom"
                  v-model="menufrom"
                  :close-on-content-click="false"
                  :return-value.sync="urine2CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine2CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 2"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine2CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menufrom = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menufrom.save(urine2CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-select
                  v-model="urine3"
                  :items="urineItems"
                  label="ผลการตรวจปัสสาวะครั้งที่ 3"
                  disabled
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12" v-if="urine3">
                <v-menu
                  ref="menuto"
                  v-model="menuto"
                  :close-on-content-click="false"
                  :return-value.sync="urine3CheckDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="urine3CheckDate"
                      label="วันที่ตรวจปัสสาวะครั้งที่ 3"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                      append-icon="mdi-clock"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="urine3CheckDate" no-title scrollable>
                    <v-btn text color="primary" @click="menuto = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto.save(urine3CheckDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-card
                outline
                width="100%"
                class="ma-3 pa-4"
                v-if="urine == 'พบสารเสพติด' || acceptFlag == true"
              >
                <v-col cols="12"
                  ><v-row>
                    <v-col cols="12" md="12" sm="12" xs="12">
                      <h3 style="font-weight: 800; color: #833133">
                        การดูแลหลังการบำบัด
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" xs="12">
                      <v-select
                        v-model="supportive"
                        :items="supportiveItems"
                        label="การดูแล ส่งเสริม สนับสนุนผู้เสพหลังรับการบำบัด"
                        disabled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" xs="12">
                      <v-select
                        v-model="afterDrug"
                        :items="afterDrugitems"
                        label="การดำเนินชีวิตหลังการบำบัด"
                        disabled
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-row>
            <v-row>
              <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
              <!-- <v-col>
                <v-btn color="primary" @click="submit(identityId)"
                  >บันทึก
                </v-btn></v-col> -->
            </v-row>
          </v-form>
          <!-- <v-col cols="12" md="12" sm="12">
            <v-btn
              color="green darken-1"
              dark
              absolute
              bottom
              right
              fixed
              fab
              style="margin-bottom: 65px"
              @click="printpo"
              v-if="printvisible"
              class="btnPrint"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-col> -->
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      output: null,
      printvisible: true,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      id: "",
      identityId: "",
      name: "",
      nickname: "",
      gender: "",
      age: "",
      address: "",
      nationality: "",
      otherNationality: "",
      urine: "",
      acceptFlag: false,
      urineItems: [
        { text: "ไม่พบสารเสพติด", value: "ไม่พบสารเสพติด" },
        { text: "พบสารเสพติด", value: "พบสารเสพติด" },
      ],
      urine2: "",
      urine3: "",
      urine2CheckDate: new Date().toISOString().substr(0, 10),
      urine3CheckDate: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      phone: "",
      drugTimes: "",
      drugType: "",
      drugTypeOther: "",
      otherPerson: "",
      otherPersonDetail: "",
      pic1: "",
      picture_1: "",
      imageName1: "ภาพถ่าย(ถ้ามี)",
      pic2: "",
      picture_2: "",
      imageName2: "ภาพถ่าย(ถ้ามี)",
      treatment: "",
      treatmentItems: [
        { text: "เข้ารับการบำบัด", value: "เข้ารับการบำบัด" },
        { text: "ไม่เข้ารับการบำบัด", value: "ไม่เข้ารับการบำบัด" },
      ],
      afterDrug: "",
      afterDrugitems: [
        { text: "มีอาชีพ", value: 1 },
        { text: "กลับมาเสพ", value: 2 },
        { text: "กลับมาเป็นผู้ขาย", value: 3 },
        { text: "ยังว่างงาน", value: 4 },
      ],
      updatedAt: "",
      supportive: "",
      supportiveItems: [
        { text: "ฝึกอาชีพ", value: "ฝึกอาชีพ" },
        { text: "จ้างงาน ", value: "จ้างงาน" },
        { text: "การศึกษา ", value: "การศึกษา" },
        { text: "ดูแลตนเอง ", value: "ดูแลตนเอง" },
      ],
      caseType: "",
      caseTypeItems: [
        { text: "คดีจำหน่าย", value: "คดีจำหน่าย" },
        { text: "คดีครองครองเพื่อจำหน่าย", value: "คดีครองครองเพื่อจำหน่าย" },
        { text: "คดีครอบครอง", value: "คดีครอบครอง" },
        { text: "คดีเสพ", value: "คดีเสพ" },
      ],
      createdUser: "",
    };
  },
  async created() {
    var data = JSON.parse(Decode.decode(localStorage.getItem("data")));
    console.log("dadadada", data);
    this.id = data.id;
    this.identityId = data.identityId;
    this.name = data.name;
    this.nickname = data.nickname;
    this.gender = data.gender; //
    if (data.age) {
      this.age = data.age.toString();
    } //
    this.address = data.address; //
    this.nationality = data.nationality;
    this.otherNationality = data.otherNationality;
    // this.houseNo = data.houseNo;
    // this.moo = data.moo;
    this.urine = data.urine;
    this.urine2 = data.urine2;
    this.urine3 = data.urine3;
    this.urine2CheckDate = data.urine2CheckDate; //
    this.urine3CheckDate = data.urine3CheckDate; //
    this.urine2CheckDate = data.urine2CheckDate; //
    this.urine3CheckDate = data.urine3CheckDate; //
    this.acceptFlag = data.acceptFlag; //
    this.phone = data.phone; //
    this.drugTimes = data.drugTimes; //
    this.otherPerson = data.otherPerson;
    this.otherPersonDetail = data.otherPersonDetail;
    this.drugType = data.drugType; //
    this.pic1 = data.pic1; //
    this.pic2 = data.pic2; //
    this.area = data.area; //
    this.province = data.province; //
    this.station = data.station; //
    this.userId = data.id; //////
    this.treatment = data.treatment;
    this.supportive = data.supportive;
    this.caseType = data.caseType;
    this.afterDrug = data.afterDrug;
    this.updatedAt = moment(String(data.updatedAt)).format("DD/MM/YYYY hh:mm");

    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/user/getOne/` + data.userId
    );

    this.createdUser = response.data.data.name;
  },
  methods: {
    // convertDate(value) {
    //   if (this.updatedAt) {
    //     return moment(String(this.updatedAt)).format("MM/DD/YYYY hh:mm");
    //   }
    // },
    cancel() {
      this.$router.push("ManageForm3");
    },
    // async submit() {
    //   const data = {
    //     id: this.id,
    //     identityId: this.identityId,
    //     name: this.name,
    //     nickname: this.nickname,
    //     houseNo: this.houseNo,
    //     moo: this.moo,
    //     urine: this.urine,
    //     treatment: this.treatment,
    //     supportive: this.supportive,
    //     caseType: this.caseType,
    //   };
    //   console.log("data", data);
    //   const response = await this.axios.post(
    //     `${process.env.VUE_APP_API}/drugrelatedlist/update`,
    //     data
    //   );
    //   if (response.data.response_status === "SUCCESS") {
    //     console.log("response", response);
    //     await this.$swal.fire({
    //       showConfirmButton: false,
    //       timer: 1500,
    //       timerProgressBar: true,
    //       icon: "success",
    //       text: "แก้ไขข้อมูลสำเร็จ",
    //     });
    //     this.$router.push("ManageForm3");
    //   } else {
    //     await this.$swal.fire({
    //       showConfirmButton: false,
    //       timer: 1500,
    //       timerProgressBar: true,
    //       icon: "error",
    //       text: "แก้ไขข้อมูลไม่สำเร็จ",
    //     });
    //   }
    // },
    async printpo() {
      await setTimeout(() => {
        // window.print();
        // Pass the element id here
        this.$htmlToPaper("printMe");
      }, 500);
      this.printvisible = false;
      setTimeout(() => {
        this.printvisible = true;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>